<template>
  <Layout>
    <template
      v-if="row.label"
      v-slot:label
    >
      {{ row.label }}
    </template>
    <template
      v-if="row.extra"
      v-slot:extra
    >
      {{ row.extra }}
    </template>
    <template
      v-if="row.prepend"
      v-slot:prepend
    >
      {{ row.prepend }}
    </template>

    <template v-slot:input>
      <input
        v-model="model"
        :disabled="isDisabled"
        class="px-1 py-0.5 text-xs leading-tight text-white bg-gray-600 border border-gray-600 rounded-sm appearance-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:border-gray-900 focus:text-white"
        :class="{ 'opacity-75 cursor-not-allowed' : isDisabled }"
        type="text"
      >
    </template>
  </Layout>
</template>
<script>
import inputs from './InputMixin'

export default {
  name: 'TextInput',

  mixins: [inputs],
  
  props: ['row', 'rowkey', 'keypath'],

  data () {
    return {
      model: this.row.value
    }
  },

  computed: {
    isDisabled () {
      if (this.row.disabled) {
        return this.row.disabled
      }
      return false
    }
  }

  // methods: {
  //   debounceChange () {
  //     debounce(this.emitChange(), 500)
  //   },

  //   emitChange () {
  //     this.$bus.$emit('editor:change', {
  //       key: this.keypath,
  //       value: this.model
  //     })
  //   }
  // },

  // watch: {
  //   model () {
  //     // this.debounceChange()

  //     // this.$bus.$emit('editor:change', {
  //     //   key: this.keypath,
  //     //   value: this.model
  //     // })
  //   }
  // }
}

</script>
